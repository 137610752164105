import React from 'react';
import { HashRouter, Route, Link } from "react-router-dom";
import Sidebar from './components/Sidebar';

import routes from "./routes";


function App() {

  return (
    <div>
      {/* Navigation Sidebar */}
      <Sidebar />

      {/* Content */}
      <div  className="w3-main" style={{margin: '0px 40px 0px 340px'}}>
        <HashRouter>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            );
          })}
        </HashRouter>

        {/* Footer */}
        <div className="w3-light-grey w3-container w3-padding-32" style={{margin:'75px -40px 0px -40px', padding:'0px 88px 0px 0px'}}>
          <p className="w3-right-align">
            Impuls.Ing GmbH | Wassergrabe 6 | 6210 Sursee<br></br>
            041 508 16 24<br></br>
            <HashRouter>
              <span className="w3-small"><Link to="/datenschutz">Datenschutz</Link>  [Build 5c37982]</span>
            </HashRouter>
          </p>
        </div>
      </div>

    </div>
  );
};

export default App;
